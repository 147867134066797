import React from 'react';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

import { auth, signInWithGoogle } from '../../firebase/firebase.utils';
import { signInWithEmailAndPassword } from 'firebase/auth';

import './sign-in.styles.scss';


class SignIn extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        }
    }

    handleSubmit = async event => {
        event.preventDefault();

        const { email, password } = this.state;
        try {
            await signInWithEmailAndPassword(auth, email, password);

            // Clear up after successful sign in 
            this.setState({ email: '', password: '' });
        } catch (error) {
            console.error(error);
        }

        this.setState({ email: '', password: '' });
    }

    handleChange = event => {
        const { value, name } = event.target;

        this.setState({ [name]: value });
    }

    render() {
        return (
            <div className='sign-in'>
                <h2>I already have an account</h2>
                <span>Sign in with your email and password</span>

                <form onSubmit={this.handleSubmit }>
                    <FormInput name='email' type='email' value={this.state.email} handleChange={this.handleChange} required label='email' />
                    <FormInput name='password' type='password' value={this.state.password} handleChange={this.handleChange} required label='password' />
                    <div className='buttons'>
                        <CustomButton type="submit" value='Submit Form'>Sign In</CustomButton>
                        <CustomButton onClick={signInWithGoogle} isGoogleSignIn type="button">
                            {' '}
                            Sign In with Google{' '}
                        </CustomButton>
                    </div>
                    
                </form>
            </div>
        )
    }
};

export default SignIn;